<template>
  <div class="dec_rank_line">
    <svg :width="width" height="4">
      <rect width="4" height="4" style="fill:#1BFFE4;" />
      <line x1="2" y1="2" :x2="(width - 2)" y2="2" style="stroke:rgba(255,255,255,0.4); stroke-width:1"/>
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: Number,
      default: 184
    }
  }
}
</script>
<style lang="scss" scoped>
.dec_rank_line {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
  padding: 7px 0;
}
</style>
